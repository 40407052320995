import styled from 'styled-components';
const BoldFont = 'Rubik-Bold';

const Section = styled.div`
  background-color: #f1f1f1;
  width: 100%;
  float: left;
  padding: 30px 119px 30px 98px;
  background-color: #f1f1f1;
  font-family: ${BoldFont};
  @media (max-width: 1000px) {
    padding: 30px 6vw;
  }

  @media (max-width: 500px) {
    padding: 30px 10px;
  }
`;

const Header = styled.h1`
  border: none;
  text-transform: capitalize;
  font-weight: normal;
  color: #898989;
  padding: 10px 15px;
  margin: 10px 0;
  font-size: 36px;
  font-family: ${BoldFont};
`;

const ContentWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 14px;
  font-family: ${BoldFont};
  .marginLeft {
    margin-left: 36pt;
  }
`;

const OList = styled.ol`
  margin-top: 0pt;
  margin-bottom: 0pt;
  counter-reset: item;
`;

const List = styled.li`
  list-style-type: decimal;
  font-size: 11pt;
  font-family: Calibri;
  background-color: transparent;
  margin-left: -18pt;
  display: block;
  &:before {
    content: counters(item, '.') " ";
    counter-increment: item;
    float: left;
    margin-right: 10px;
    font-weight: ${({disableWeight}) => !disableWeight && '700'};
  }
`;

const SmallHeader = styled.p`
  font-size: 11pt;
  font-family: Calibri;
  background-color: transparent;
  font-weight: ${({disableWeight}) => !disableWeight && '700'};
  white-space: pre-wrap;
  text-align: ${({textCenter}) => textCenter && 'center'};
  margin: 0px;
  margin-left: ${({marginLeft}) => marginLeft && marginLeft};
  margin-top: ${({marginTop}) => marginTop && marginTop};
  > a {
    color: #428bca;
    margin-left: 4px;
    margin-right: 4px;
    text-decoration: none;
  }
`;

const ParagraphWrapper = styled.span`
  font-size: 11pt;
  font-family: Calibri;
  background-color: transparent;
  font-style: ${({italic}) => italic && 'italic'};
  text-decoration-line: ${({underline}) => underline && 'underline'};
  white-space: pre-wrap;
  color: ${({color}) => color && color };
`;

export { Section, Header, ContentWrapper, SmallHeader, ParagraphWrapper, OList, List }
