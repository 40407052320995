import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const Section = styled.div`
  background-color: #f1f1f1;
  width: 100%;
  float: left;
  padding-bottom: 40px;
  background-color: #f1f1f1;
  font-family: ${BoldFont};
  position: relative;
  
  .openbtn {
      font-size: 23px;
      cursor: pointer;
      color: #3e7f9f;
      padding: 10px 15px;
      border: none;
      display: none;
      outline: none;
      background: none;
  }
  
  .sidepanel {
      width: 300px; 
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: #f4fbff;
      background-image: linear-gradient(rgb(230,246,255),rgb(207,237,255));
      padding: 76px 10px 10px 60px;
      overflow-x: hidden; /* Disable horizontal scroll */
      transition: 0.5s all; /* 0.5 second transition effect to slide in the sidepanel */
      
      & > ol {
        margin-top: 0pt;
        margin-bottom: 0pt;
        
        > li {
          font-size: 16px;
          background-color: transparent;
          margin-left: -18pt;
          letter-spacing: 2px;
          line-height: 58px;
          color: #074658;
          font-family: ${MediumFont};
          
          & > a {
          color: #074658
          
            &:hover {
               text-decoration: none;
            }
          }
        }
      }
  
  }

  .sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .buttoncontainer {
    display: block;
    padding-left: 60px;
  }
  
  @media (min-width: 768px) {
    .sidepanel {
      display: none;
    }
  }
    
  @media (max-width: 768px) {
    .openbtn {
        display: block;
    }
    .sidepanel {
      display: block;
    }  
  }
  
  @media (max-width: 450px) {
    .buttoncontainer {
       padding-left: 20px;
    }
  }
  
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
  
`;
const LeftContainer = styled.div`
  float: left;
  width: 35%;
  background-color: #f4fbff;
  background-image: linear-gradient(rgb(230,246,255),rgb(207,237,255));
  padding: 90px 10px 150px 115px;
  
  & > ol {
    margin-top: 0pt;
    margin-bottom: 0pt;
    padding-left:20px;
    
    > li {
      font-size: 15px;
      background-color: transparent;
      margin-left: -18pt;
      letter-spacing: 2px;
      line-height: 34px;
      color: #074658;
      font-family: ${MediumFont};
      margin: 20px 0px;
      word-break: break-word;
      
      & > a {
      color: #1D5779;
      
        &:hover {
           text-decoration: none;
        }
      }
    }
  }
    
  @media (max-width: 768px) {
    display: none;
  }
  
  
  
  
`;

const ListTitle = styled.p`
  text-align: left;
  color: #1D5779;
  font-family: ${BoldFont};
  font-size: 21px;
  letter-spacing: 1px;
  font-weight: 700;
`;

const Header = styled.h1`
  border: none;
  text-transform: capitalize;
  font-size: 32px;
  line-height: 52px;
  color: #2b281e;
  font-family: ${BoldFont};
  margin: 0;
  margin-bottom: 25px;
  
  & > p { 
    font-size: 19px;
    line-height: 40px;
    color: #2b281e;
    font-family: ${RegularFont};
    font-weight: 700;
  }
 
`;

const ContentWrapper = styled.div`
  float: left;
  width: 65%;
  padding: 90px 119px 30px 76px;
  font-size: 14px;
  font-family: ${BoldFont};
  > ol {
    margin-top: 0pt;
    font-family: ${RegularFont}
    margin-bottom: 0pt;
    margin-left: 20px;
    > li {
      font-size: 25px;
      background-color: transparent;
      margin-left: -18px;
      font-family: ${RegularFont}
    }
    
    >li::before {
    
    }
  }
  > ul {
    margin-top: 30px;
    margin-bottom: 0pt;
    padding-left: 0;
    > li {
      list-style-type: disc;
      font-size: 16px;
      font-family: Arial;
      background-color: transparent;
      margin-left: 18pt;
    }
    div{
      > img {
      width: 26px;
      height: auto;
      float: left;
      margin-right: 10px;
      margin-top: 3px;
    }

    }
    
    > img {
      width: 26px;
      height: auto;
      float: left;
      margin-right: 10px;
      margin-top: 3px;
    }
  }
  .marginLeft {
    margin-left: 36pt;
  }
  .marginTop {
    margin-top: 20px;
  }
  
  .listContainer {
    list-style-type: none;
    padding-left: 16px;
  }
   
  @media (max-width: 768px) {
    width: 100%
  }
  
  @media (max-width: 768px) {
    padding: 76px 76px 30px 70px;
  }
  
  @media (max-width: 450px) {
    padding: 76px 30px 30px 30px;
    > ol {
      margin-left: 35px;
    }
  }
`;

const SmallHeader = styled.p`
  font-size: ${({fontSize}) => fontSize ? fontSize : '11pt'};
  background-color: transparent;
  font-weight: ${({disableWeight}) => !disableWeight && '700'};
  white-space: pre-wrap;
  text-align: ${({textCenter}) => textCenter && 'center'};
  margin: 0px;
  margin-left: ${({marginLeft}) => marginLeft && marginLeft};
  margin-top: ${({marginTop}) => marginTop && marginTop};
  font-family: ${RegularFont}
  line-height: ${(lineHeight) => lineHeight && '32px'}
  color: #2b281e;
  word-break: break-word;
  > a {
    color: #428bca;
    margin-left: 4px;
    margin-right: 4px;
    text-decoration: none;
  }
  
  > p {
     font-size: 18px;
     font-family: ${BoldFont};
  }
  
`;

const ParagraphWrapper = styled.span`
  font-size: 15px;
  color: #2b281e;
  font-family: ${RegularFont};
  background-color: transparent;
  font-style: ${({italic}) => italic && 'italic'};
  text-decoration-line: ${({underline}) => underline && 'underline'};
  white-space: pre-wrap;
  color: ${({color}) => color && color };
  font-weight: ${({disableWeight}) => !disableWeight && '700'};
  > span {
    font-family: ${BoldFont}
    font-weight: ${({disableWeight}) => disableWeight ? 'normal' : '700'};
    font-style: ${({fontStyle}) => fontStyle ? 'normal' : 'italic'};
  }
`;
const CopyRight = styled.div`
    font-size: 11pt;
    background-color: transparent;
    white-space: pre-wrap;
    margin: 0px;
    margin-left: 5px;
    margin-top: 20px;
    font-family: ${RegularFont};
    line-height: 26px;
    color: #2b281e;
    word-break: break-word;
`;

export { Section, Header, ContentWrapper, SmallHeader, ParagraphWrapper, LeftContainer, ListTitle, CopyRight}