
/* eslint-disable no-undef */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NavigationBarV2, LandingFooterPanelV2, Layout } from '../../components';

import {Section, Header, ContentWrapper, SmallHeader, ParagraphWrapper, ListTitle, LeftContainer, CopyRight} from './styles';

import { checkPermission } from '../../utils/methods';
import TryAmigo from '../../components/AboutPageV2/TryAmigo';
import LazyImage from '../../components/common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

const applicationTerms =  {
  title: 'Applicable Terms',
  content: 'These Terms of Use (the "Terms" or "Agreement") set forth the terms of use under which you may use the Woliba.com site, Woliba.io site and/or the Woliba Services (also branded as Woliba), further described below. Certain Woliba Services are subject to additional policies, rules and terms and conditions, which are set forth in the printed or online Service materials relating to the Woliba Services and/or in your Subscriber Agreement (collectively, the "Applicable Terms")',
  terms: [
    'Please read these Terms and any additional Applicable Terms before using the Site or the Services. By using the Site or the Services, you represent, warrant, understand, agree to and accept these Terms and any additional Applicable Terms in their entirety whether or not you have a Subscriber Agreement and are thus a registered user ("Registered User").',
    'These Terms include the terms of the Woliba Privacy Policy, which are incorporated herein by reference. If you object to anything in these Terms, the Privacy Policy or any additional Applicable Terms, you must not use the Site or any of the Services.',
    'These Terms, including the Privacy Policy and any additional Applicable Terms, are subject to change by Woliba, Inc. (“Woliba” or “we”) at any time. We will endeavor to notify you about significant changes in these Terms by sending a notice to the email address registered in your account, or by placing a prominent notice on our Site, so that you can choose whether to continue using our Services, but it is your responsibility to check these Terms regularly for changes. Any use of the Site and/or acceptance of Services after the effective date of any changes will constitute acceptance of such changes. These Terms, as may be amended as described above, shall supersede all prior versions of the Terms and all other agreements and understandings between you and Woliba with respect to the subject matter in these Terms.'
  ]
};

const excluseTerms = [
  "Websites, web pages, mobile applications and mobile websites (and services offered through such channels) operated by Woliba's other subsidiaries, if any, including without limitation, any international subsidiaries. The sites and services offered by such other subsidiaries will be subject to separate terms to which users of such sites and services must separately agree and that will be found on their respective sites and/or service materials or within the relevant applications.",
  "Websites, web pages, mobile applications, and mobile websites (and services offered through such channels) operated by Woliba which have separate terms of service that do not expressly incorporate these Terms by reference. The sites and services offered by Woliba via such channels are subject to separate terms to which a user of such sites and services must separately agree and that can be found on their respective sites and/or service materials or within the relevant applications."
];

const copyRight = {
  title: 'Copyrights',
  content: 'The Website is protected by all applicable U.S. and international copyright laws and its contents, and all rights therein, belong exclusively to Woliba. By using or accessing the Website, you agree to be bound by these Terms of Use, which may be revised in our sole discretion by amending the Terms of Use on the Website without any additional notice to you.'
};

const intellectualProperty = {
  title: "Other Intellectual Property",
  content: "Unless otherwise expressly set forth herein, Woliba is the owner of all patents, copyrights, brands and marks (and the rights to such brands and marks), including trademarks, service marks, trade dress, trade names, corporate names, proprietary logos or indicia and other source or business, trade secrets and other intellectual property rights which are presented or identified in this Website, including but not limited to, all Woliba software."
};

const noLicence = {
  title: "No License",
  content: "All products, technologies and processes described in this Website, and associated intellectual property rights are owned by Woliba and nothing in the Website is deemed to grant or imply a license to such rights."
};

const restrictions = {
  title: "Restrictions on Use",
  content: 'You may download, view, copy and print documents and graphics on the Website subject to the following: (a) the documents and graphics may be used solely for personal, informational, non-commercial purposes, or the purposes permitted by any separate agreement with Woliba (a “Subscriber Agreement”); (b) the documents and graphics may not be modified or altered in any way except as expressly authorized in a Subscriber Agreement; and (c) You agree to be bound by a Subscriber Agreement. You may not use, download, upload, copy, print, display, perform, reproduce, publish, license, post, transmit or distribute any information from this Website in whole or in part except as authorized by a Subscriber Agreement or the express written authorization of Woliba.'
};

const liablity = {
  title: "Limitation of Liability",
  content: "This Website and the information within are provided on an “as is” basis without warranty of any kind, either express or implied, such as, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Woliba makes no warranty that (a) the Website will meet your requirements; (b) the website will be available on an uninterrupted, timely, error-free basis; or (c) the results that may be obtained from the use of the website or any services offered through the website will be accurate or reliable. Except to the extent provided by applicable law, under no circumstances will Woliba be liable to any party for any direct, indirect, special, consequential, incidental or punitive damages caused from the use of this website, or on any other hyper-linked website, including, without limitation, any lost profits, business interruption, loss of programs or other data, even if Woliba is expressly advised of the possibility of such damages.  As such, it is your responsibility to ensure that you have alternative sources for the service, adequate back-up files, and sufficient redundancy for your purposes."
};

const indemnity = {
  title: "Indemnity",
  content: "You agree to defend, indemnify and hold harmless Woliba, its officers, directors, employees and agents from and against any and all claims, liabilities, damages, losses or expenses, including reasonable attorneys’ fees and costs, arising out of or in any way connected with your access to or use of the Website except to the extent caused by any breach of these Terms by Woliba, the negligent acts or omissions of Woliba, or any violation of law by Woliba."
};

const law = {
  title: "Applicable Law",
  content: "The terms and conditions herein and all matters relating to your access to, and use of, the Website shall be governed by U.S. federal law and the laws of the State of New York."
};

const registrationRestrictions = {
  title: "Registration, Posting, and Content Restrictions",
  content: 'The following rules pertain to "Content", defined as any communications, images, sounds, videos, and all the material, data, and information that you upload or transmit through the Site or Services, or that other users upload or transmit, including without limitation any content, messages, photos, audios, videos, reviews or profiles that you publish or display (sometimes hereinafter, "post"). By transmitting and submitting any Content while using our Site/Service, you represent and warrant as follows:',
  list: [
    {
      title: 'Accuracy',
      content: 'You are responsible for providing accurate, current and complete information in connection with your use of the Site and the Services.'
    },
    {
      title: 'English',
      content: 'Unless specifically directed otherwise, all Content you post will be in English as the Site and Services are not currently supported in any other languages.'
    },
    {
      title: 'Responsibility for Content',
      content: "You are solely responsible for any Content that you post on the Site or transmit to other users of the Site. You will not post on the Site, or transmit to other users, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another party’s rights (including, but not limited to, intellectual property rights, and rights of privacy and publicity), or advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse, or gives the impression that any Content emanates from Woliba, or state or imply that any Content you transmit or post has been recommended, endorsed or otherwise approved by Woliba, or by any other party, unless you have received in advance that party’s explicit, written approval to make such a claim."
    },
    {
      title: 'Rights to Post',
      content: 'Your use of the Service, including but not limited to the Content you post on the Service, must be in accordance with any and all applicable laws and regulations. Without limiting the foregoing, you represent and warrant to us that you have the right and authority to post all information you post about yourself or others. Furthermore, subsequent to posting Content, if you become aware, or reasonably suspect, that it may contain material inaccuracies, you agree to correct promptly or to remove promptly any such materially inaccurate material, and to notify us of your finding. You further represent and warrant that public posting and use of your Content by Woliba will not infringe or violate the rights of any third party.'
    },
    {
      title: 'Woliba’s Rights to Remove and License to Use',
      content: 'You understand and agree that Woliba may, in its sole discretion, review and delete any Content, in each case in whole or in part, that in the sole judgment of Woliba violates these Terms or which Woliba determines in its sole discretion might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of users of the Site or others. You represent that you have the right to grant, and you hereby grant, to Woliba, its affiliates, licensees and successors, an irrevocable, perpetual, non-exclusive, fully paid-up, transferable, worldwide license to use, copy, perform, display, reproduce, adapt, modify and distribute your Content and to prepare derivative works of, or incorporate into other works, such Content, and to grant and authorize sublicenses of the foregoing.'
    },
    {
      title: 'Disclaimer of Liability',
      content: 'Woliba is not responsible for any claims relating to any inaccurate, untimely or incomplete information provided by You or other users of the Site.'
    },
    {
      title: 'Non-Confidentiality and Ownership of Your Submissions',
      content: 'You recognize that any content you post anywhere in connection with Woliba must contain only non-confidential information, and you warrant that you understand that any content you transmit to be published in a Woliba profile or elsewhere on the site will not be treated as confidential except to the extent required by law. Any comments, ideas, messages, questions, suggestions, or other communications you send to us or share with us through any channel (including, without limitation, the Site, email, telephone, surveys, and our social media accounts) shall be and remain the exclusive property of Woliba and we may use all such communications, all without notice to, consent from, or compensation to you.'
    },
    {
      title: 'Third Party Content',
      content: 'Opinions, advice, statements, offers, or other information or content made available on the Site or through the Service, but not directly by Woliba, may be those of third parties and their respective authors. Such authors are solely responsible for such content. Woliba does not: (i) guarantee the accuracy, completeness, or usefulness of any information on the Site or available through the Site/Service, or (ii) adopt, endorse or accept responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party that appears on the Site or through the Service. Under no circumstances will Woliba or its affiliates be responsible for any loss or damage arising out of: a) your reliance on information or other content posted on the Site or transmitted to or by any user of the Site or Service; or b) reviews or comments made about you on the Site by other users.'
    },
    {
      title: 'Limitation of Liability',
      content: 'To the maximum extent permitted by law, Woliba disclaims any liability whatsoever for any misstatements and/or misrepresentations made by any users of the Site. You hereby represent, understand and agree to hold Woliba harmless for any misstatements and/or misrepresentations made by you or on behalf of you on this Site or in any other venue.'
    }
  ]
};

const representationUse = {
  title: "Representations on Use",
  content: "By using the Site or Services of Woliba, you agree that you will not under any circumstances: ",
  list: [
    'attempt to re-register with the Site if we have terminated your account for any or no reason or terminate your registration and re-register;',
    "interfere or attempt to interfere with the proper functioning of the Site and/or Services;",
    "make any automated use of the system, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;",
    "bypass any robot exclusion headers or other measures we take to restrict access to the Service or use any software, technology, or device to scrape, spider, or crawl the Site/Service or harvest or manipulate data;",
  ]
};

const protectiveAction = {
  title: 'Protective Action',
  content: 'In order to protect our users from prohibited activity, including unwanted messages, we reserve the right to take appropriate actions, including but not limited to restricting the number of messages that a user may send over a given period to a level which we deem appropriate in our sole discretion.'
};

const terminationAccess = {
  title: 'Termination of Access',
  content: 'In the event that Woliba finds that you have violated any of the terms stated in these Terms of Use, Woliba reserves the right, immediately, and at its sole discretion, to suspend or terminate your access and/or use of the Site and/or Services.'
};

const trainingAmigoActionTer = {
  title: 'Woliba Actions Upon Termination',
  content: ' In the event that Woliba determines that you are not eligible to use the Website/Services, have violated any terms stated herein or in any of the additional Applicable Terms, are not suitable for participation as a Registered User, or have misused or misappropriated the Site, the Services or any of their materials, including but not limited to use on a “mirrored,” competitive, or third-party site, Woliba reserves the right, at its sole discretion, to immediately terminate your access to all or part of the Site and/or Services, to remove your profile and/or any content posted by, about or related to you from the Site, and/or to terminate your registration with Woliba, with or without notice. Upon termination, Woliba shall be under no obligation to provide you with a copy of any content posted by, about or related to you on the Site, except to the extent required by law including without limitation, the General Data Protection Regulation of the European Union, the California Consumer Privacy Act of 2018 ( “CCPA”), the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), Subtitle D of the HITECH Act (Title XIII of the American Recovery and Reinvestment Act of 2009) and other data protection and privacy laws (collectively, the Privacy Laws), or otherwise expressly provided under these Terms.'
};

const additionalRightTer = {
  title: 'Additional Right Upon Termination',
  content: 'Without limitation of the foregoing, subject to applicable law, including the Privacy Laws, Woliba also reserves the right, in its sole discretion, to terminate your access as a user, after consultation with your employer or our client providing you access, to all or part of the Site and/or Services, to remove your profile and/or any content posted by, about, or related to you from the Site, and/or to terminate your registration with Woliba, for any reason or no reason, with or without notice. If we terminate your registration, we have no obligation to notify you of the reason, if any, for such termination.'
};

const postTer = {
  title: 'Post-Termination',
  content: "Following any termination of any individual’s use of the Site or the Services, Woliba reserves the right to notify other Registered Users with whom we believe the terminated user has corresponded. Our decision to terminate a User’s registration, or that of a Member or Subscriber, and/or to notify other Registered Users with whom we believe the terminated user has corresponded does not constitute, and should not be interpreted or used as, information bearing on the character, general reputation, personal characteristics, or mode of living of any natural person, or of the organization to which the terminated user may belong or have belonged, whether or not such organization is, or has been, a Member or Subscriber of our Services."
};

const natureLink = {
  title: 'Nature of Links',
  content: "Links from the Site to external sites (including external sites that may be framed by Woliba) or inclusion of advertisements do not constitute an endorsement by Woliba of such sites or of the content, products, advertising and other materials presented on such sites or of the products and services that are the subject of such advertisements, do not indicate any association between Woliba and the owners or operators of such site, and do not create any warranty by Woliba regarding the safety of such sites/links. Such links are provided for users’ reference and convenience only. Users access to any links shall be at the users’ own risk. It is the responsibility of the user to evaluate the content and usefulness of the information obtained from other sites. Woliba does not control such sites and is not responsible for their content. The use of any site which is controlled, owned or operated by third parties is governed by the terms and conditions of use for those sites, and not by Woliba’s Terms of Use and/or Privacy Policy."
};

const limitationLink = {
  title: 'Limitation of Liability Relating to Links',
  content: 'Woliba expressly disclaims any liability derived from the use and/or viewing of links that may appear on the Site. All users hereby agree to hold Woliba harmless from any liability that may result from the use of links that may appear on the Site.'
};

const additionalLimitation = [
  {
    title: 'LIMITATION OF LIABILITY RELATING TO ACCESS/INFORMATION AND CAP ON LIABILITY',
    content: "Except to the extent provided by applicable Privacy Laws or otherwise expressly provided under these Terms:"
  },
  {
    title: '(a)',
    content: "Except to the extent provided by applicable Privacy Laws or otherwise expressly provided under these Terms: In no event will Woliba be liable for any damages related to any information received from the Site or Services, removal of content from the Site, including profile information, any email distributed to any user or any linked web site or use thereof or inability to use by any party, or in connection with any termination of your subscription or ability to access the Site or Services, failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if Woliba, or representatives thereof, are advised of the possibility of such damages, losses or expenses."
  },
  {
    title: '(b)',
    content: "UNDER NO CIRCUMSTANCES WILL WOLIBA’S AGGREGATE LIABILITY, IN ANY FORM OF ACTION WHATSOEVER IN CONNECTION WITH THIS AGREEMENT OR THE USE OF THE SERVICES OR THE SITE, EXCEED THE AMOUNT OF FEES PAID TO Woliba TO USE THE WEBSITE OR Woliba’S SERVICES DURING THE SIX (6) MONTHS PRECEDING THE CLAIM, OR, IF YOU HAVE NOT PAID Woliba FOR THE USE OF ANY SERVICES, THE AMOUNT OF $25.00 OR ITS EQUIVALENT."
  },
  {
    title: '(c)',
    content: "IN NO EVENT WILL Woliba BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, AND/OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE SITE OR THE SERVICES, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM ANYONE’S RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE SITE, OR TRANSMITTED TO OR BY ANY USERS OR ANY OTHER INTERACTIONS WITH OTHER REGISTERED USERS OF THE SITE OR SERVICES, WHETHER ONLINE OR OFFLINE. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO DEFRAUD OR HARM YOU."
  },
  {
    title: 'Indemnification Procedures',
    content: 'With respect to any and all indemnities given in connection with these Terms: (a) Woliba and its affiliates reserve the right, at their own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you; (b) you further agree to cooperate as reasonably required in Woliba’s defense of such claims; and (c) you agree that you shall not, in any event, settle any claim or matter without the written consent of Woliba.'
  }
];

const copyRightPolicy = [
  'an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;',
  'a description of the copyrighted work or other intellectual property that you claim has been infringed;',
  'a description of where the material that you claim is infringing is located on Woliba’s services;',
  'your address, telephone number, and email address;',
  'a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;',
  " and a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.",
];

const sideContent = [
  'General','Rights to Material', 'No Warranty', 'Rules for User Conduct and Use of Website/Services',
  'Prohibited Uses', 'Termination of Registration', 'Privacy',
  'Links to External Sites', 'Reporting and Release of Liability for Conduct and Disputes', 'Additional Limitation of Liability',
  'DMCA Copyright Policy'
];

class TermsOfCondition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.myref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.myref && this.myref.current && !this.myref.current.contains(e.target) && this.state.open === true ) {
      this.setState({open: false});
    }
  };

  renderConditions = (arr, bool) => (
    arr.map((content, index) => (
      <ul key={index}>
        <LazyImage src={ImageUrl + "/images/PrivacyPolicy/listimage.png"} />
        <li className="listContainer">
          <div key={index} className="marginTop">
            <ParagraphWrapper disableWeight={true}>{content}</ParagraphWrapper>
          </div>
          { bool &&  <p>&nbsp;</p> }
        </li>
      </ul>
    ))
  );

  renderTitle = (title, start) => (
    <ol start={start}>
      <li>
        <SmallHeader id = {title} fontSize="25px" disableWeight={1}>{title}</SmallHeader>
      </li>
    </ol>
  );

  renderSmallTitles = (title, content) => (
    <SmallHeader disableWeight={1}>
      <ParagraphWrapper italic={1} disableWeight = {false}><span>{title}</span></ParagraphWrapper>
      <ParagraphWrapper disableWeight = {true}>
        .&nbsp; {content} 
      </ParagraphWrapper>
    </SmallHeader>
  );

  smallTitlesWithList = (arr) => (
    arr.list.map((obj, index) => (
      <ul key={index}>
        <LazyImage src={ImageUrl + "/images/PrivacyPolicy/listimage.png"} />
        <li className={"listContainer"}>
          {this.renderSmallTitles(obj.title, obj.content)}
          <p>&nbsp;</p>
        </li>
      </ul> 
    ))
  );

  renderSideContent = () => (
    <ol>
      {
        sideContent.map((title, index) => (
          <li key={index}>
            <a href={`#${title}`} onClick={this.closeNav}>{title}</a>
          </li>
        ))
      }
    </ol>
  );

  openMobileMenu = () => (
    this.setState({open: true})
  );

  closeNav = () => (
    this.setState({open: false})
  );
  
  render() {
    const { history, showFooterAndHeader } = this.props;
    const {open} = this.state;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;   
    return(
      <Layout>
        {showFooterAndHeader && <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />}
        <Section>
          {showFooterAndHeader &&
            <LeftContainer>
              <ListTitle>
                TERM OF USE
              </ListTitle>
              {this.renderSideContent()}
            </LeftContainer>
          }
          {open ?
            <div id="mySidepanel" className="sidepanel" ref={this.myref}>
              <a href="#" className="closebtn" onClick={this.closeNav}>&times;</a>
              {this.renderSideContent()}
            </div>: ""
          }
          {showFooterAndHeader &&
          <div className="buttoncontainer">
            <button className="openbtn" onClick={this.openMobileMenu}>
              <i className="fa fa-bars" />
            </button>
          </div>
          }
          <ContentWrapper>
            { showFooterAndHeader && <Header>Woliba Terms of Use
              <p>Updated February 2022</p>
            </Header> }
            <SmallHeader disableWeight={1} lineHeight={1}>
              PLEASE READ THESE TERMS OF USE CAREFULLY. THESE TERMS MAY HAVE CHANGED SINCE YOUR LAST
              VISIT TO THIS WEBSITE. BY ACCESSING THIS WEBSITE OR USING ANY OF THE SERVICES RELATING TO
              THIS WEBSITE, YOU CONFIRM YOUR ACCEPTANCE OF THESE TERMS OF USE.
            </SmallHeader>
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1} lineHeight={1}>
              THE INFORMATION PRESENTED ON THIS WEBSITE IS IN NO WAY INTENDED AS MEDICAL ADVICE OR AS A
              SUBSTITUTE FOR MEDICAL TREATMENT. THIS INFORMATION SHOULD ONLY BE USED IN CONJUNCTION
              WITH THE GUIDANCE AND CARE OF A PROPERLY LICENSED PHYSICIAN, WITH WHOM YOU SHOULD
              CONSULT BEFORE BEGINNING ANY DIET, NUTRITION, OR FITNESS PLAN OFFERED THROUGH THE
              WEBSITE. THE PHYSICIAN SHOULD ALLOW FOR PROPER FOLLOW-UP VISITS AND INDIVIDUALIZE YOUR
              DIET, NUTRITION, OR FITNESS PLAN AS APPROPRIATE. NOTHING STATED OR PRESENTED ON THE
              WEBSITE IS INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR
              TREATMENT. ALWAYS SEEK THE ADVICE OF A PHYSICIAN OR OTHER QUALIFIED HEALTH CARE PROVIDER
              IF YOU HAVE ANY QUESTIONS REGARDING A MEDICAL CONDITION, YOUR DIET, NUTRITIONAL
              SUPPLEMENTS, AN EXERCISE REGIMEN, OR ANY OTHER MATTER RELATED TO YOUR HEALTH AND WELL-
              BEING.
            </SmallHeader>
            <p>&nbsp;</p>
            {this.renderTitle("General", 1)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(applicationTerms.title, applicationTerms.content) }
            <p>&nbsp;</p>
            { this.renderConditions(applicationTerms.terms, true) }
            <SmallHeader disableWeight={1} className="marginLeft">
              <ParagraphWrapper disableWeight={true}>
                {'**For purposes of these Terms, and except to the extent expressly excluded below, the "Site" or “Website” shall mean'}
              </ParagraphWrapper>
              <a href="https://www.trainingamigo.com/">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">www.TrainingAmigo.com,</ParagraphWrapper>
              </a>
              <span>&#44;</span>
              <a href="https://woliba.io/">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">www.woliba.io</ParagraphWrapper>
              </a>
              <ParagraphWrapper disableWeight={true}>
                {'and any other applications, websites, web pages, including any mobile versions operated by Woliba in the United States and the "Services" shall mean any of the various services that Woliba provides through the Site or any other channels, including without limitation, over the telephone. The "Site" and "Services" also may include certain sites and services offered by authorized resellers, channel partners and affiliates to the extent explicitly described in these Terms.'}
              </ParagraphWrapper>
            </SmallHeader>
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1}>
              <ParagraphWrapper disableWeight ={1}>
                <span>Exclusions. </span>
              </ParagraphWrapper>
              <ParagraphWrapper disableWeight={true}>
                {' However, the terms "Site" and "Services" do not include or involve the following:'}
              </ParagraphWrapper>
            </SmallHeader>
            <p>&nbsp;</p>
            { this.renderConditions(excluseTerms, true) }
            <ul>
              <LazyImage src={ImageUrl + "/images/PrivacyPolicy/listimage.png"} />
              <li className="listContainer">
                <SmallHeader disableWeight={1}>
                  <ParagraphWrapper disableWeight={true}>
                    Third-party entities that may use the 
                  </ParagraphWrapper>
                  {/*<a href="https://www.trainingamigo.com/">
                    <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">www.TrainingAmigo.com</ParagraphWrapper>
                  </a>*/}
                  <ParagraphWrapper disableWeight={true}>
                    Site or Services. Such entities use of the Site and Services are subject to separate terms to which those entities agreed upon when engaging with Woliba.
                  </ParagraphWrapper>
                </SmallHeader>
              </li>
            </ul>
            <p>&nbsp;</p>
            {this.renderTitle("Rights to Material", 2)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(copyRight.title, copyRight.content) }
            <br/>
            { this.renderSmallTitles(intellectualProperty.title, intellectualProperty.content) }
            <br/> 
            { this.renderSmallTitles(noLicence.title, noLicence.content) }
            <br/>
            { this.renderSmallTitles(restrictions.title, restrictions.content) }
            <p>&nbsp;</p>
            {this.renderTitle("No Warranty", 3)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(liablity.title, liablity.content) }
            <br/> 
            { this.renderSmallTitles(indemnity.title, indemnity.content) }
            <br/>
            { this.renderSmallTitles(law.title, law.content) }
            <br/>
            <SmallHeader disableWeight={1}>
              <ParagraphWrapper disableWeight={true}>
                <span>Contact.</span>
              </ParagraphWrapper>
              <ParagraphWrapper disableWeight={true}>
                .&nbsp; If you have any questions regarding these Terms of Use, please contact Woliba at
              </ParagraphWrapper>
              <a href="mailto:Support@trainingamigo.com">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">Support@trainingamigo.com</ParagraphWrapper>
              </a>
              <span>or</span>
              <a href="mailto:Support@woliba.io.">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">Support@woliba.io</ParagraphWrapper>
              </a>
              <span>&#46;</span>
            </SmallHeader>
            <p>&nbsp;</p>
            {this.renderTitle("Rules for User Conduct and Use of Website/Services", 4)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(registrationRestrictions.title, registrationRestrictions.content)}
            <p>&nbsp;</p>
            {this.smallTitlesWithList(registrationRestrictions)}
            <p>&nbsp;</p>
            {this.renderTitle("Prohibited Uses", 5)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(representationUse.title, representationUse.content)}
            <p>&nbsp;</p>
            {this.renderConditions(representationUse.list, false)}
            <ul >
              <LazyImage src={ImageUrl + "/images/PrivacyPolicy/listimage.png"} />
              <li className="listContainer">
                <SmallHeader disableWeight={1}>
                  <ParagraphWrapper disableWeight={true}> use the communication systems provided by or contacts made on </ParagraphWrapper>
                  <a href="https://www.trainingamigo.com/">
                    <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">www.TrainingAmigo.com</ParagraphWrapper>
                  </a>
                  <ParagraphWrapper disableWeight={true}>for any commercial solicitation purposes;</ParagraphWrapper>
                </SmallHeader>
              </li>
            </ul>
            <ul >
              <LazyImage src={ImageUrl + "/images/PrivacyPolicy/listimage.png"} />
              <li className="listContainer">
                <SmallHeader disableWeight={1}>
                  <ParagraphWrapper disableWeight={true}>
                    {"publish or link to malicious content intended to damage or disrupt another user's browser or computer."}
                  </ParagraphWrapper>
                </SmallHeader>
              </li>
            </ul>
            <p>&nbsp;</p>
            { this.renderSmallTitles(protectiveAction.title, protectiveAction.content)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(terminationAccess.title, terminationAccess.content)}
            <p>&nbsp;</p>
            {this.renderTitle('Termination of Registration', 6)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(trainingAmigoActionTer.title, trainingAmigoActionTer.content)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(additionalRightTer.title, additionalRightTer.content)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(postTer.title, postTer.content)}
            <p>&nbsp;</p>
            {this.renderTitle('Privacy', 7)}
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1}>
              <ParagraphWrapper disableWeight={1}>
                Woliba’s use of the information you provide in connection with the Site and/or our Services are governed by our Privacy Policy and all applicable Privacy Laws.
                For more information, see our full Privacy Policy currently located at
              </ParagraphWrapper>
              <a href="https://www.TrainingAmigo.com/privacy-policy">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">
                  www.TrainingAmigo.com/privacy-policy
                </ParagraphWrapper>
              </a>
              <span>and</span>
              <a href="https://woliba.io/privacy-policy">
                <ParagraphWrapper underline={1} color="rgb(0, 0, 255)">
                  www.woliba.io/privacy-policy
                </ParagraphWrapper>
              </a>
              <ParagraphWrapper disableWeight={1}>, the terms of which are incorporated herein by reference.</ParagraphWrapper>
            </SmallHeader>
            <p>&nbsp;</p>
            {this.renderTitle('Links to External Sites', 8)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(natureLink.title, natureLink.content)}
            <p>&nbsp;</p>
            { this.renderSmallTitles(limitationLink.title, limitationLink.content)}
            <p>&nbsp;</p>
            {this.renderTitle('Reporting and Release of Liability for Conduct and Disputes', 9)}
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1}>
              <ParagraphWrapper disableWeight={1}>
                You hereby expressly agree to hold Woliba harmless for any dispute, claim or controversy that may arise between you and any Member,
                Subscriber or other user(s) of the Site. You agree to take reasonable precautions in all interactions with Subscribers’ users of the Site or the Services,
                particularly if you decide to exchange information or to meet outside the confines of the Site or its Service. By using the Site or the Services, you hereby agree to report any alleged improprieties of
                any users therein to Woliba immediately by notifying Woliba of the same via electronic correspondence.
              </ParagraphWrapper>
            </SmallHeader>
            <p>&nbsp;</p>
            {this.renderTitle('Additional Limitation of Liability', 10)}
            <p>&nbsp;</p>
            { additionalLimitation.map((list, index) => (
              <div key={index}>
                {this.renderSmallTitles(list.title, list.content)}
                <p>&nbsp;</p>
              </div>
            ))}
            {this.renderTitle('DMCA Copyright Policy', 11)}
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1}>
              <ParagraphWrapper disableWeight={true}>
                {"It is Woliba’s policy to respond to notices of alleged copyright infringement in accordance with the Digital Millennium Copyright Act (DMCA). Woliba respects the intellectual property of others, and we expect our users to do the same. Woliba may suspend and/or terminate the accounts of users who infringe the rights of others. If you believe that your copyrights or other intellectual property rights have been infringed by postings appearing on Woliba services, you should provide Woliba’s copyright agent with the following information:"}
              </ParagraphWrapper>
            </SmallHeader>
            <p>&nbsp;</p>
            {this.renderConditions(copyRightPolicy, false)}
            <p>&nbsp;</p>
            <CopyRight>
                Copyright Agent Woliba <br />
              31 Hudson Yards · Floor 11 · New York, NY 10001
            </CopyRight>
          </ContentWrapper>
        </Section>
        {showFooterAndHeader && <TryAmigo
          history={history}
        />}
        {showFooterAndHeader && <LandingFooterPanelV2 />}
      </Layout>  
    )
  }
}

TermsOfCondition.propTypes = {
  history: PropTypes.object.isRequired,
  showFooterAndHeader: PropTypes.bool
};

export default withRouter(TermsOfCondition);
